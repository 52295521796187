.App {
  margin: 10px;
  font-family: "Cairo";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Button {
  cursor: pointer;
}

.NavBar .Link {
  float: left;
  font-family: "VT323";
  background: #aaa;
  color: #222;
  padding: 0px 10px 0px 10px;
  margin-left: 2px;
  margin-top: 2px;
  margin-bottom: 0px;
}

.NowPlaying {
  align-items: left;
  color: white;
}

.TopBar {
  margin-bottom: 10px;
}

a {
  color: #fff;
  text-decoration: none;
}

body {
  background-color: #282c34;
  color: #fff;
}

.Banner {
  position: absolute;
  top: -40px;
  right: 400px;
  height: 215px;
  z-index: -1;
  filter: grayscale(100%)
}

button.PlayerButton {
  margin-left: 5px;
  padding-top: 4px;
  width: 41px;
  height: 41px;
  background: #000;
  border: 1px solid #888;
  border-radius: 3px;
  color: #ccc;
  float: left;
}

canvas {
  margin: 0;
}

button.LoginBox {
  border: 1px solid #aaa;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: #ccc;
}

button.Logout {
  font-family: "VT323";
  font-size: 16px;
  padding: 0px 10px 0px 10px;
  float: right;
  margin-top: 2px;
  margin-right: 2px;
  background: #aaa;
  border: 0px;
  color: #222;
}

button.StandardButton {
  font-family: "VT323";
  background: #aaa;
  color: #222;
  padding: 0px 10px 0px 10px;
  margin-left: 2px;
  margin-top: 2px;
  margin-bottom: 0px;
  border: 0px;
}

div.error {
  text-align: center;
  color: #a22;
}

div.DetailPanel {
  flex-basis: 500px;
  flex-grow: 1;
  margin-right: 5px;
  margin-top: 5px;
  border: 1px solid #666;
  border-radius: 5px;
  overflow: hidden;
  background: #222;
  padding: 0px;
}

div.Label {
  font-weight: bold;
  margin-right: 5px;
}

div.Logs {
   overflow: scroll;
   height: 700px;
   font-family: "VT323";
   font-size: 12px;
}

div.LoginContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

div.MainPanel {
  flex-basis: 500px;
  flex-grow: 1;
  margin-right: 5px;
  margin-top: 5px;
  border: 1px solid #666;
  border-radius: 5px;
  overflow: hidden;
  background: #222;
  padding: 0px;
}

div.MainPanelSelector {
  padding-top: 5px;
  padding-left: 10px;
  background: #666;
}

div.Message {
  height: 30px;
  margin-left: 10px;
  margin-bottom: 20px;
}

div.NavBar {
  clear: both;
  padding: 0px;
  border: 2px solid #666;
  background: #222;
  height: 20px;
}

div.OperatorContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

div.PageContent {
  clear: both;
}

div.Player {
  width: 380px;
  float: right;
  border: 2px solid #666;
  background: #222;
  margin-bottom: 5px;
  padding: 0px;
}

div.Spectrum {
  background: #000;
  border: 1px solid #888;
  border-radius: 3px;
  margin-left: 6px;
  padding-left: 6px;
  padding-right: 5px;
  float: left;
  position: relative;
}

div.SpectrumEmpty {
  border-radius: 3px;
  margin-left: 6px;
  padding-left: 6px;
  padding-right: 5px;
  float: left;
  position: relative;
}

div.SpeechResponse {
  margin-left: 10px;
}

div.StationName {
  font-size: 32px;
  color: #fff;
}

div.Tagline {
  color: #888844;
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 36px;
}

.ContactBar {
  color: #888888;
  font-size: 14px;
  font-style: italic;
}

div.TopText {
  float: left;
}

div.VolumeContainer {
  float: right;
  position: relative;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 100px;
  height: 34px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  border: 1px solid #888;
  border-radius: 3px;
  background: #000;
}

img.Tuner {
  margin: 0px;
}

img.TrackEditSpinner {
  vertical-align: middle;
  margin-right: 10px;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100px;
  background: transparent;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none;
}

/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 14px;
  width: 13px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
  margin-top: -3.5px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 12px;
  width: 10px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 12px;
  height: 12px;
  width: 10px;
  border-radius: 3px;
  background: #ccc;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #666;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #666;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #666;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #666;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-lower {
  background: #666;
}

input[type=range]::-ms-fill-upper {
  background: #666;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]:focus::-ms-fill-upper {
  background: #666;
}

input.SpeechInput {
  width: 75%;
  margin-left: 5px;
  margin-right: 5px;
}

input.TextInput {
  background: #fff;
  border: 0px;
  box-shadow: 0px;
}

input.TrackEdit {
  width: 95%;
}

input.TrackSubEdit {
  width: 30%;
  margin-left: 10px;
}

input.VolumeSlider {
  margin: 0px;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 70px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

li.MainTrack {
  font-size: 20px;
  margin-left: 10px;
}

li.Track {
  font-size: 14px;
  margin-left: 10px;
}

nav {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

pre {
    margin: 0px;
}

span.Artist {
  color: #888;
  margin-left: 5px;
}

span.PanelButtonSelected {
  background: #777;
}

span.PanelSelectorButton {
  padding: 5px;
  border-radius: 5px;
}

span.PanelSelectorButton:hover {
  background: #555;
}

span.SortArrow {
  font-size: 10px;
}

span.TrackControls {
  padding-bottom: 0px;
}

span.TrackEditLabel {
  font-weight: bold;
  margin-left: 10px;
}

span.TrackEditSpinner {
  display: inline-block;
  width: 15px;
}

span.VolumeUp {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ccc;
}

span.UtilButton {
  border: 1px solid #555;
}

span.With {
  margin-left: 5px;
  color: #888;
}

span.UpNext {
  margin-left: 5px;
  color: #888;
}

table.AxesSelector {
  width: 100%;
  padding: 2px;
}

table.AxesTable {
  width: 100%;
  border-spacing: 0px;
}

table.LoginBox {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #aaa;
  background: #222;
  border-spacing: 0px;
}

table.PanelTable {
  padding: 0px;
  border-spacing: 0px;
  background: #222;
  width: 100%;
  overflow: scroll;
}

table.Status {
  background: #22222288;
  border: 1px solid #aaa;
  border-collapse: collapse;
  width: 100%;
  overflow: scroll;
}

table.SearchTable {
  width: 100%;
  background: #222;
}

table.TrackEdit {
  width: 100%;
  border-bottom: 1px solid #666;
  border-spacing: 0px;
  border-collapse: collapse;
  background: #222;
  padding: 0px;
}


table.TrackList {
  width: 100%;
  background: #111;
  color: #cccccc;
  font-size: 12px;
}

table.TracksContainer {
  width: 100%;
  background: #222;
  border-spacing: 0px;
}

td.AxisSelected {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  vertical-align: center;
  border: 1px solid #666;
  background: #666;
}

td.AxisUnselected {
  text-align: center;
  font-size: 12px;
  padding: 5px;
  vertical-align: center;
  border: 1px solid #666;
}

td.AxesColumn {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  padding: 5px;
}

td.PanelTableHeader {
  background: #444;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
}

td.SearchTableLabel {
  text-align: right;
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
}

td.SearchTableValue {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
}

td.StatusHeader {
  background: #FFFFFF37;
  padding: 0px 10px 0px 10px;
  font-size: 16px;
}

td.Status {
  font-size: 14px;
  overflow: scroll;
}

td.Time {
  font-size: 12px;
  color: #888;
}

td.Track {
  font-size: 14px;
  padding-left: 5px;
}

td.TrackSmall {
  font-size: 12px;
  padding-left: 5px;
}

td.TrackControls {
  background: #666;
  padding-top: 10px;
  padding-left: 10px;
  vertical-align: middle;
}

td.TrackEditAxes {
  width: 25%;
  vertical-align: top;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

td.TrackEditButtons {
  text-align: center;
}

td.TrackEditIcons {
  width: 5%;
  vertical-align: top;
  padding: 10px;
}

td.TrackEditLabel {
  width: 10%;
  text-align: right;
  font-weight: bold;
  padding-left: 5px;
}

td.TrackEditValue {
  width: 60%;
  padding-right: 5px;
  padding-left: 5px;
}

td.TrackAlbumColumn {
  width: 25%;
  text-align: left;
}

td.TrackArtistColumn {
  width: 25%;
  text-align: center;
}

td.TrackIdColumn {
  width: 5%;
  text-align: right;
}

td.TrackList {
  padding-left: 3px;
  padding-right: 3px;
}

td.TrackTitleColumn {
  width: 33%;
  text-align: left;
}

td.TrackTypeColumn {
  width: 12%;
  text-align: center;
}

td.UtilButtonColumn {
  padding: 10px;
}

th.AxesTableHeader {
  background: #444;
  text-align: left;
  padding-left: 10px;
}

th.SearchTableHeader {
  background: #666;
  text-align: left;
  padding-left: 10px;
}

th.TableHeader {
  background: #666;
  text-align: left;
  padding-left: 10px;
}

th.TrackEditorHeader {
  background: #444;
  text-align: left;
  font-weight: normal;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
}

th.TrackList {
  background: #444;
}

tr.AxesRow:nth-child(even) {
  background: #222;
}

tr.Status {
  border: 1px solid #666;
  padding: 0px;
}

tr.TrackList:nth-child(even) {
  background: #222;
}

ul.Tracks {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}